.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Change from center to flex-start */
    height: 100vh;
    background-color: #f0f0f0; /* Light grey background */
    padding: 20px;
    padding-top: 200px; /* Adjust this value to move the text further down or up */
  }
  
  
  .login-title {
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 0; /* Add this line to reduce any top margin */
    text-align: center;
  }
  
  .login-google-button {
    font-size: 18px; /* Increase font size */
    padding: 15px 30px; /* Increase padding */
    background-color: #4285f4; /* Google blue color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-google-button:hover {
    background-color: #357ae8; /* Slightly darker blue on hover */
  }
  
  .login-google-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(66, 133, 244, 0.5); /* Blue outline on focus */
  }
  