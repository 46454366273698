.summary-container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 800px;
}

.summary-container h2 {
  margin-bottom: 10px;
}

.summary-table {
  border-collapse: collapse;
  width: 100%;
  max-width: 500px;
}

.summary-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.summary-table tr td:first-child {
  font-weight: bold;
  background-color: #f2f2f2;
}

.summary-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.json-display {
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.section {
  margin-bottom: 20px;
  max-width: 800px;
}

.question-block {
  margin-bottom: 10px;
  max-width: 800px;
}

.answer-block {
  margin-bottom: 10px;
  font-style: italic;
  max-width: 800px;
}

h2,
h3,
h4 {
  color: #333;
}