.buy-credits-container {
  max-width: 500px;
  margin: 80px auto;
  padding: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  font-family: 'Arial', sans-serif;
}

.buy-credits-container h2 {
  text-align: center;
  margin-bottom: 25px;
  color: #333;
  font-size: 24px;
  font-weight: 600;
}

.order-summary {
  padding: 25px;
  background-color: #fafafa;
  border-radius: 12px;
  border: 1px solid #ddd;
}

.order-summary label {
  display: block;
  margin-bottom: 8px;
  color: #444;
  font-size: 14px;
  font-weight: 500;
}

.order-summary select,
.order-summary textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

.order-summary select:focus,
.order-summary textarea:focus {
  border-color: #007bff;
  outline: none;
}

textarea {
  resize: vertical;
  min-height: 120px;
  max-height: 240px;
}

.cost-details {
  margin-top: 20px;
  padding: 20px;
  background-color: #f1f1f1;
  border-radius: 12px;
  border: 1px solid #e0e0e0;
}

.cost-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 16px;
}

.cost-item span {
  color: #555;
}

.cost-item.total {
  border-top: 1px solid #ddd;
  padding-top: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.cost-item.total span {
  color: #000;
}

.buy-button {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.buy-button:hover {
  background-color: #218838;
}

@media (max-width: 600px) {
  .buy-credits-container {
    width: 90%;
    margin: 40px auto;
    padding: 20px;
  }

  .order-summary {
    padding: 20px;
  }

  .buy-button {
    font-size: 16px;
  }
}

.payment-iframe-container {
  margin-top: 20px;
  border-radius: 8px;
  overflow: hidden;
}

.payment-iframe-container iframe {
  width: 100%;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}