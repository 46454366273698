.loading-bar-container {
  width: 100%;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin-top: 10px;
}

.loading-bar {
  height: 10px;
  background-color: #4CAF50;
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
}