.file-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 2px dashed #cccccc;
  border-radius: 5px;
  background-color: #fafafa;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.uploader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  width: 100%;
}

.upload-icon {
  font-size: 48px;
  margin-bottom: 10px;
}

.upload-text {
  margin-bottom: 10px;
}

.choose-file-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.file-info {
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.summarize-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #40b140;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.summarize-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.loading-bar-container {
  margin-top: 10px;
  width: 100%;
  max-width: 300px;
}